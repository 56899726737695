import moment from "moment";
import { PreAgendamentoRowResponse } from "../types/PreAgendamentos/preAgendamento";

export const filtroDeDatasDesejadas = (date: Date, admin: Boolean = false) => {
  const dataDesejada = moment(date).startOf('day').format();
  const startValidDate = admin ? moment().startOf('day').format() : moment().add(2, 'days').startOf('day').format();
  return moment(dataDesejada).isSameOrAfter(startValidDate);
}

export const filtroDeDatasDisponiveis = (date: Date, listaDatasDisponivels: string[]) => listaDatasDisponivels?.some((data: string) => moment(data).format() === moment(date).format());

export function removeDuplicatesStrings(list1: string[], list2: string[]): string[] {
  const combinedList = [...list1, ...list2];
  const uniqueSet = new Set(combinedList);
  const uniqueArray = Array.from(uniqueSet);
  return uniqueArray?.filter((item) => item !== '');
}

export function toSortedPreAgendamentos(list: PreAgendamentoRowResponse[]) {
  return list?.sort((a: PreAgendamentoRowResponse, b: PreAgendamentoRowResponse) => Number(b.ticket) - Number(a.ticket));
}